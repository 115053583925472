import styled from 'styled-components'
import { AutoColumn } from '../Column'
import Title from '../Title'
import { BasicLink } from '../Link'
import { useMedia } from 'react-use'
import { transparentize } from 'polished'
import { withRouter } from 'react-router-dom'
import { TrendingUp, CheckCircle, LogOut, Repeat, Sunrise, Link2, FastForward } from 'react-feather'
import { Award, Heart, GitHub, MessageCircle, BookOpen, Home, List } from 'react-feather'
import { RouteComponentProps } from 'react-router-dom'
import { ExternalLink } from '../../theme'
import { useGetBridgeFeature } from '../../state/user/hooks'

import { useWeb3React } from '@web3-react/core'
import { ChainId } from '@arec/sdk'

const Wrapper = styled.div<{ isMobile?: boolean }>`
  height: ${({ isMobile }) => (isMobile ? 'initial' : '100vh')};
  background-color: ${({ theme }) => transparentize(0.4, theme.bg1)};
  padding: 0rem 0.5rem 0rem 0.75rem;
  position: sticky;
  top: 0px;
  z-index: 9999;
  box-sizing: border-box;
  background: ${({ theme }) => theme.sideBar}; //rgba(40,254,44,0.6);
  color: ${({ theme }) => theme.sideBarText};

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    position: relative;
  }

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`
// background: rgba(0,192,239,0.5);   // Original Blue
// Good: background: linear-gradient( rgba(0,192,239,0.5), rgba(0,192,239,0.5));
//background: linear-gradient(193.68deg, #6000A0 0.68%, #300060 100.48%);
// background: linear-gradient( rgba(0,192,239,0.5), rgba(0,192,239,0.5));
//background: linear-gradient(193.68deg, #6000A0 0.68%, #300060 100.48%);
//linear-gradient(rgba(0,192,239,0.3), rgba(250,234,229,0.3));
//#rgba(250,234,229,0.3)

const Option = styled.div<{ activeText?: boolean }>`
  width: 10.5rem;
  height: 2rem;
  line-height: 2rem;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme, activeText }) => (activeText ? theme.sideBar : theme.sideBarText)};
  background-color: ${({ theme, activeText }) => (activeText ? theme.sideBarText : theme.sideBar)};
  border-radius: 6px;
  display: flex;
  &:focus,
  &:hover {
    border: none;
    color: ${({ theme }) => theme.sideBar};
    background-color: ${({ theme }) => theme.sideBarText};
  }
`

export const MenuItem = styled(ExternalLink)`
  flex: 1;
  width: 10.5rem;
  height: 1.6rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.sideBarText};
  border-radius: 6px;
  :focus,
  :hover {
    color: ${({ theme }) => theme.sideBar};
    cursor: pointer;
    background-color: ${({ theme }) => theme.sideBarText};
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`

/*
const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
*/

/*
const HeaderText = styled.div`
  margin-right: 0.5rem;
  font-size: 0.825rem;
  font-weight: 500;
  display: inline-box;
  display: -webkit-inline-box;
  opacity: 0.8;
  :hover {
    opacity: 1;
    cursor: pointer;
    text-decoration: none;
    underline: none;
  }
  a {
    color: ${({ theme }) => theme.text2};
  }
`
*/

/*
const Polling = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  padding: 1rem;
  color: white;
  opacity: 0.4;
  transition: opacity 0.25s ease;
  :hover {
    opacity: 1;
  }
`
const PollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  margin-top: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.green1};
`

*/

function SideNav({ history }: RouteComponentProps) {
  const below1080 = useMedia('(max-width: 1080px)')
  const { chainId } = useWeb3React()

  const bridgeFeature = useGetBridgeFeature()

  const showExchange = false

  //  const below1180 = useMedia('(max-width: 1180px)')

  //  const [isDark, toggleDarkMode] = useDarkModeManager()
  // const showBridge = useAtomValue(isIssuer)
  const ifCelo = chainId === ChainId.CELO || chainId === ChainId.CELO_TESTNET

  const ifSimple = history.location.pathname === '/quicks' || history.location.pathname === '/quick'

  return (
    <Wrapper isMobile={below1080}>
      {!below1080 && (
        <DesktopWrapper>
          <AutoColumn gap="1rem" style={{ marginLeft: '.75rem', marginTop: '1.5rem' }}>
            <Title />
            {!below1080 && (
              <AutoColumn gap="0.5rem" style={{ marginTop: '0.5rem' }}>
                <BasicLink to="/overview" draggable="false" style={{ width: '10.5rem' }}>
                  <Option
                    activeText={history.location.pathname === '/overview' || history.location.pathname === '/profile'}
                  >
                    <TrendingUp
                      size={18}
                      style={{ marginTop: '0.4rem', marginLeft: '0.6rem', marginRight: '0.5rem' }}
                    />
                    Overview
                  </Option>
                </BasicLink>

                {!ifCelo && (
                  <BasicLink style={{ width: '10.5rem' }} draggable="false" to={ifSimple ? '/quicks' : '/quick'}>
                    <Option activeText={history.location.pathname.split('/')[1] === 'quick'}>
                      <FastForward
                        size={18}
                        style={{ marginTop: '0.4rem', marginLeft: '0.6rem', marginRight: '0.5rem' }}
                      />
                      Quick Start
                    </Option>
                  </BasicLink>
                )}

                {!ifCelo && !ifSimple && (
                  <BasicLink style={{ width: '10.5rem' }} draggable="false" to="/Issuance">
                    <Option activeText={history.location.pathname === '/Issuance'}>
                      <CheckCircle
                        size={18}
                        style={{ marginTop: '0.4rem', marginLeft: '0.6rem', marginRight: '0.5rem' }}
                      />
                      Issuance
                    </Option>
                  </BasicLink>
                )}

                {ifCelo && !ifSimple && (
                  <BasicLink style={{ width: '10.5rem' }} draggable="false" to="/bridge">
                    <Option activeText={history.location.pathname.split('/')[1] === 'bridge'}>
                      <Link2 size={18} style={{ marginTop: '0.4rem', marginLeft: '0.6rem', marginRight: '0.5rem' }} />
                      AREC Bridge
                    </Option>
                  </BasicLink>
                )}

                {!ifSimple && (
                  <BasicLink style={{ width: '10.5rem' }} draggable="false" to="/Redeem">
                    <Option
                      activeText={history.location.pathname === '/Redeem' || history.location.pathname === '/Liquidize'}
                    >
                      <LogOut size={18} style={{ marginTop: '0.4rem', marginLeft: '0.6rem', marginRight: '0.5rem' }} />
                      Retirement
                    </Option>
                  </BasicLink>
                )}

                {!ifSimple && (
                  <BasicLink style={{ width: '10.5rem' }} draggable="false" to="/Offset">
                    <Option
                      activeText={history.location.pathname === '/Offset' || history.location.pathname === '/Solidify'}
                    >
                      <Heart size={18} style={{ marginTop: '0.4rem', marginLeft: '0.6rem', marginRight: '0.5rem' }} />
                      Offset
                    </Option>
                  </BasicLink>
                )}

                {!ifSimple && (
                  <BasicLink style={{ width: '10.5rem' }} draggable="false" to="/mintCert">
                    <Option activeText={history.location.pathname === '/mintCert'}>
                      <Award size={18} style={{ marginTop: '0.4rem', marginLeft: '0.6rem', marginRight: '0.5rem' }} />
                      Badge
                    </Option>
                  </BasicLink>
                )}

                {!ifCelo && showExchange && !ifSimple && (
                  <BasicLink style={{ width: '10.5rem' }} draggable="false" to="/swap">
                    <Option
                      activeText={
                        (history.location.pathname.split('/')[1] === 'swap' ||
                          history.location.pathname.split('/')[1] === 'add') ??
                        undefined
                      }
                    >
                      <Repeat size={18} style={{ marginTop: '0.4rem', marginLeft: '0.6rem', marginRight: '0.5rem' }} />
                      Exchange
                    </Option>
                  </BasicLink>
                )}

                {!ifCelo && bridgeFeature === 'on' && !ifSimple && (
                  <BasicLink style={{ width: '10.5rem' }} draggable="false" to="/bridge">
                    <Option activeText={history.location.pathname.split('/')[1] === 'bridge'}>
                      <Link2 size={18} style={{ marginTop: '0.4rem', marginLeft: '0.6rem', marginRight: '0.5rem' }} />
                      AREC Bridge
                    </Option>
                  </BasicLink>
                )}

                {(ifCelo || bridgeFeature === 'on') && !ifSimple && (
                  <BasicLink style={{ width: '10.5rem' }} draggable="false" to="/port">
                    <Option activeText={history.location.pathname.split('/')[1] === 'port'}>
                      <Sunrise size={18} style={{ marginTop: '0.4rem', marginLeft: '0.6rem', marginRight: '0.5rem' }} />
                      AREC Port
                    </Option>
                  </BasicLink>
                )}

                {(ifCelo || bridgeFeature === 'on') && !ifSimple && (
                  <BasicLink style={{ width: '10.5rem' }} draggable="false" to="/cART">
                    <Option activeText={history.location.pathname.split('/')[1] === 'cART'}>
                      <Repeat size={18} style={{ marginTop: '0.4rem', marginLeft: '0.6rem', marginRight: '0.5rem' }} />
                      {ifCelo ? `cART Swap` : `AKRE Swap`}
                    </Option>
                  </BasicLink>
                )}
              </AutoColumn>
            )}
          </AutoColumn>
          <AutoColumn gap="0.2rem" style={{ marginLeft: '.75rem', marginBottom: '4rem' }}>
            <MenuItem id="link" href="https://www.arkreen.com" draggable="false" style={{ padding: '0px' }}>
              <Home size={14} style={{ marginLeft: '0.6rem', marginRight: '0.4rem' }} />
              <span style={{ fontWeight: 400, fontSize: '14px' }}> Arkreen </span>
            </MenuItem>
            <MenuItem id="link" href="https://explorer.arkreen.com" draggable="false" style={{ padding: '0px' }}>
              <List size={14} style={{ marginLeft: '0.6rem', marginRight: '0.4rem' }} />
              <span style={{ fontWeight: 400, fontSize: '14px' }}> Explorer </span>
            </MenuItem>
            <MenuItem id="link" href="https://docs.arkreen.com" draggable="false" style={{ padding: '0px' }}>
              <BookOpen size={14} style={{ marginLeft: '0.6rem', marginRight: '0.4rem' }} />
              <span style={{ fontWeight: 400, fontSize: '14px' }}> Docs </span>
            </MenuItem>
            <MenuItem id="link" href="https://github.com/arkreen" draggable="false" style={{ padding: '0px' }}>
              <GitHub size={14} style={{ marginLeft: '0.6rem', marginRight: '0.4rem' }} />
              <span style={{ fontWeight: 400, fontSize: '14px' }}> Github </span>
            </MenuItem>
            <MenuItem id="link" href="https://twitter.com/arkreen_network" draggable="false" style={{ padding: '0px' }}>
              <MessageCircle size={14} style={{ marginLeft: '0.6rem', marginRight: '0.4rem' }} />
              <span style={{ fontWeight: 400, fontSize: '14px' }}> Twitter </span>
            </MenuItem>
          </AutoColumn>
        </DesktopWrapper>
      )}
    </Wrapper>
  )
}

export default withRouter(SideNav)

//<TogglePic isActive={isDark} toggle={toggleDarkMode} />
